import { LazyMotion } from 'framer-motion';
import dynamic from 'next/dynamic';

import { ApiSettings } from '@hultafors/shared/types';

import { GlobalFields } from '@hultafors/snickers/types';

const MarketPickerProvider = dynamic(() =>
  import('@hultafors/shared/context').then(
    (module) => module.MarketPickerProvider,
  ),
);

const GlobalProvider = dynamic(() =>
  import('../global-context/global-context').then(
    (module) => module.GlobalProvider,
  ),
);
const FavoritesProvider = dynamic(() =>
  import('../favorites-context/favorites-context').then(
    (module) => module.FavoritesProvider,
  ),
);
const SizeGuideProvider = dynamic(() =>
  import('../size-guide-context/size-guide-context').then(
    (module) => module.SizeGuideProvider,
  ),
);
const WorkwearProvider = dynamic(() =>
  import('../workwear-guide-context/workwear-guide-context').then(
    (module) => module.WorkwearProvider,
  ),
);

const SWRProvider = dynamic(() =>
  import('@hultafors/shared/context').then((module) => module.SWRProvider),
);

const lazyMotionFeatures = () =>
  import('./lazy-motion-features').then((res) => res.default);

export interface ApplicationProviderProps {
  children?: React.ReactNode;
  value: GlobalFields & {
    settings: ApiSettings;
  };
}

export const ApplicationProvider: React.FC<ApplicationProviderProps> = ({
  children,
  value: { settings, ...value },
}) => {
  const active = [settings?.hostname, settings?.language?.urlPrefix]
    .filter(Boolean)
    .join('/');
  return (
    <SWRProvider>
      <LazyMotion features={lazyMotionFeatures}>
        <GlobalProvider value={value} settings={settings}>
          <WorkwearProvider>
            <SizeGuideProvider>
              <FavoritesProvider settings={settings}>
                <MarketPickerProvider
                  label={value?.global?.changeLanguage ?? ''}
                  markets={value?.global?.languages || []}
                  active={active}
                >
                  {children}
                </MarketPickerProvider>
              </FavoritesProvider>
            </SizeGuideProvider>
          </WorkwearProvider>
        </GlobalProvider>
      </LazyMotion>
    </SWRProvider>
  );
};
